<div *ngIf="showBJLTable" class = "card">
    <h3>{{'select_bjl' | titleText}}</h3>

    <div *ngIf="showBJLTable" class="form-group col-md-2">
        <button (click)="callBJL()">Create New BJL</button>
    </div>

    <div *ngIf="showBJLTable">
            <app-table         
                table_title="Select BJL" 
                [inputData]="inputData" 
                (appItemEvent)="receiveTableEvent($event)">
            </app-table>
    </div>
</div>

<div *ngIf="select_smp_screen">
  
               <app-map-sd1              
                [inputData]="inputData"
                (OnSelectProceedMap) = "OnSelectProceedMap($event)"
                (callBJLMap) = "callBJL()"
                [show_smp_table]="show_smp_table"
            ></app-map-sd1>

    <div *ngIf="show_smp_table">
            <app-table                 
                table_title="selectBjl" 
                [inputData]="inputData"
                (appItemEvent)="receiveTableEventStructure($event)" >
            </app-table>
    </div>
</div>
<div *ngIf="upstream_screen">
  <h3>{{'upstream' | titleText }}</h3>
  <app-table                 
    table_title="selectBjl" 
    [inputData]="inputData"
    (appItemEvent)="receiveTableEventUpstream($event)" >
  </app-table>
</div>

<div *ngIf="smp_connectivity_flag">
  <app-smp-connectivity-sd1
    [inputData] = "tableData"
  ></app-smp-connectivity-sd1>
</div>
<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type = "ball-scale-multiple"></ngx-spinner> 
</div>