
<div *ngIf="show_form" class="[card,content]">
  <form [formGroup]="myStrForm" (ngSubmit)="onselectStructure()" 
  [style.display]="show_smp_table ? 'none' : 'block'">
    <div class="form-group">
      <label for="ada">Select BJL Structure</label>
      <input
        type="text"
        id="selectedStructure "
        formControlName="structure"
        [value]="myStrForm.get('structure')"
        name="Structure"
        required
      />
    </div>
    <button
          type="submit"
          [class]="myStrForm.invalid ? 'gray-button' : ''"
          [disabled]="myStrForm.invalid"
        >
          Submit
    </button>
  </form>
</div>

<div style="margin-top: 20px" id="map" style="width: 100%; height: 400px"></div>

<div style="margin-top: 20px; display: flex; align-items: center" >
  <button type="submit" (click)="OnSelectProceed()">Click To Proceed</button>
  <button [style.display]="show_smp_table ? 'block' : 'none'" type="submit" style="margin-left: 80%;" (click)="callBJL()">Create New BJL</button>
</div>






