<div *ngIf="select_jl_screen">
               <app-map-sd1              
                [inputData]="inputData"
                (OnSelectProceedMap) = "OnSelectProceedMap($event)"
                [show_smp_table]="show_smp_table"
            ></app-map-sd1>
    <div *ngIf="show_smp_table">
            <app-table                 
                table_title="selectBjl" 
                [inputData]="inputData"
                (appItemEvent)="receiveTableEventStructure($event)" >
            </app-table>
    </div>
</div>



<div *ngIf="smp_connectivity_flag">
  <app-smp-connectivity-sd1
    [inputData] = "tableData"
  ></app-smp-connectivity-sd1>
</div>

<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type = "ball-scale-multiple"></ngx-spinner> 
</div>