import { SelectionModel } from "@angular/cdk/collections";
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip'; 
import { ReplaceTextPipe,TitleTextPipe } from "../../../custom.pipe";
import {MatRadioModule} from '@angular/material/radio';

@Component({
  selector: "app-table",
  standalone: true,
  imports:[CommonModule,MatTableModule,
    MatTooltipModule,ReplaceTextPipe,
    TitleTextPipe,MatRadioModule,
  ],
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"]
})

export class TableComponent implements OnInit {
  
  @Input() inputData: any;
  @Input() table_title: string = '';
  tableData: any;
  displayedColumns: string[] = [];
  dataSource!: MatTableDataSource<any>;
  selection= new SelectionModel<any>(true, []);
  selectedRow: any;
  @Input() isCheckbox: boolean = false;
  @Input() isReadio: boolean = false;
  error = '';
  inputValue = '';
  text_id: number = 100;
  refresh_table: boolean = false;
  smp_state_check: boolean = false;
  hide_radio_option : boolean = false;
  

  @Output() appItemEvent = new EventEmitter<any>();
  color = "rgb(148 0 234 / 75%)"
  text_color = "white"
  text_weight="700"

  constructor() { 
      
  }

  ngOnInit() {
    this.initializeFunction(this.inputData);

  }

    isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    this.selectedRow = this.selection.selected;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  submitAction(data:any) {
    this.appItemEvent.emit(data);
  }

  initializeFunction(inputData:any) {
    this.inputData = inputData;
    // Display table data based of return message condition
    this.displayedColumns = this.inputData['table_columns'];
    if (this.inputData.message == 'select bjl structure dfn' || 
      this.inputData.message == 'select bjl structure' ||
      this.inputData.message == 'select ajl structure') {
      this.tableData = this.inputData.jl_structure_list;
    }
    else if (this.inputData.message == 'select bjl') {
      this.tableData = this.inputData.bjl_list;
    }
    else if (this.inputData.message == 'select mpt structure') {
      this.tableData = this.inputData.mpt_structure_list;
    }
    else if(this.inputData.message == 'alternate path'){
      this.tableData = this.inputData.alternate_paths;
    }
    else if (this.inputData.message == 'select upstream equipment' ||
      this.inputData.message == "select upstream equipment overwrite") {
      this.tableData = this.inputData.upstream_equipment_list;
    }
    else if (this.inputData.message == 'select ajl upstream equipment') {
      this.tableData = this.inputData.ajl_upstream_equipment_list;
    }
    else if (this.inputData.message == 'select mpt upstream equipment') {
      this.tableData = this.inputData.mpt_upstream_equipment_list;
    }
    else if (this.inputData.message == 'select existing smp') {
      this.tableData = this.inputData.locations_served_from_smp;
      this.hide_radio_option = false;
    }
    else if (this.inputData.message == 'select new smp') {
      this.tableData = this.inputData.locations_served_from_smp;
      this.hide_radio_option = true;
    }
    else if(this.inputData.message == 'sd1 process completed'){
      this.tableData = this.inputData.selected_smp;
    }
    else if (this.inputData.message == "select bjl sd1") {
      this.tableData = this.inputData.bjl_with_capacity;
    }
    else if (this.inputData.message == "select smp structure") {
      this.tableData = this.inputData.smp_structure_list;
      this.hide_radio_option = false;
    }
    else if (this.inputData.message == 'select bjl structure sd1') {
      this.tableData = this.inputData.bjl_structure_list;
      this.hide_radio_option = false;
    }
    else if (this.inputData.message == "select upstream equipment sd1") {
      this.tableData = this.inputData.upstream_equipment_list;
    }
    else if(this.inputData.message == "upload adr"){
      this.tableData = this.inputData.adr_table;
    }
    else if (this.inputData.message == 'path generation successful' ||
      this.inputData.message == 'path generation successful type2' || 
      this.inputData.message == 'path generation successful dfn') {
      for (let ele of this.inputData['available_paths']) {
        let updatedRawData = ele['raw_path'];
        let updatedTrenchData = ele['raw_trench'];
        ele['raw_trench_updated'] = updatedTrenchData.join('=> ');
        ele['raw_path_updated'] = updatedRawData.join('=> ');
        
      }
      this.tableData = this.inputData['available_paths'];
      
      this.displayedColumns = this.displayedColumns.concat('raw_trench_updated');
      this.displayedColumns = this.displayedColumns.concat('raw_path_updated');
      

      // Remove raw_trench and raw_path from display column 
      this.displayedColumns.splice(this.displayedColumns.indexOf('raw_path'), 1);
      this.displayedColumns.splice(this.displayedColumns.indexOf('raw_trench'), 1);
    }
    if (this.inputData.message == 'select existing smp' || this.inputData.message == 'select new smp') {
      this.displayedColumns = this.displayedColumns.concat('New Location');
      if('smp_state' in this.tableData[0]){
        //this.displayedColumns = this.displayedColumns.concat('smp_state');
        for (let i = 0; i < this.tableData.length; i++){
          if (this.tableData[i]['smp_state'] == 'yes' || this.tableData[i]['smp_state'] == 'no') {
            this.tableData[i]['readOnly'] = true;
          }
        }
      }
    }
    if(this.inputData.message != 'sd1 process completed' && this.inputData.message != "upload adr"){
        this.displayedColumns = this.displayedColumns.concat('actions');
    } 
    
  }


  radioSelect(data:any,row:any, i:number){
    console.log(row)
    let input_id = i;
    
    if(data.value=='yes'){   
      
      let text_field = document.getElementById('field_'+input_id);
      if(text_field!=null){
        text_field.setAttribute('readonly', 'true');
      }
    }
    else{
      let text_field = document.getElementById('field_'+input_id);
      if(text_field!=null){
        text_field.removeAttribute('readonly');
      }
    }
    if(this.text_id == input_id){
        row['new_location'] = this.inputValue;
    }
    else{this.inputValue = '';}
    row['smp_state'] = data.value;
    if(this.inputValue.length > 4 && (data.value == 'no' || data.value == 're_eval')){
       alert('No and Re-eval values are not allowed with New Location, Please select Yes.');        
    }
    else{
      this.appItemEvent.emit(row);
    }    
  }

  new_location_check(event:any){
    this.inputValue = event.target.value;
    let input_id = event.target.id;
    
    let error_id_no = input_id.split("_")[1];
    this.text_id = error_id_no;
    let error_id = 'error_'+(error_id_no);
    let err_span = document.getElementById(error_id);
    if(this.inputValue.length > 0 && this.inputValue.length < 3){
      this.error = 'Invalid Location ID';
      if(err_span != null){
        err_span.innerHTML = this.error;
      }
      //document.getElementById(input_id).value = this.error;
    }
    else if(this.inputValue.length == 0){
      if(err_span != null){
        err_span.innerHTML = '';
      }
    }
    else if (this.inputValue.length == 3){
        if(this.inputValue.substr(0, 3).toUpperCase() != 'LOC'){
            if(err_span != null){
              err_span.innerHTML = 'Location ID start with LOC';
            }
        }
    }
    else if (this.inputValue.length == 15){
        for(let i=0;i<this.tableData.length;i++){
          if(this.tableData[i].suggested_location_id == this.inputValue){
            alert(this.inputValue + ' already available in Suggested location. Please mark suggeasted location as Re-Eval.')
          }
        }
    }
    else{
      if(err_span != null){
        err_span.innerHTML = '';
      }
    }
  
    
  }

  disabled(){}
  labelPosition(){}
}
