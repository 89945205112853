
<table
  mat-table
  [dataSource]="tableData"
  class="table table-striped-columns table-bordered"
>
  <ng-container
    [matColumnDef]="column"
    *ngFor="let column of displayedColumns"
    class="table-dark"
  >
    @if(column != 'actions' && column != 'New Location'){
    <th mat-header-cell 
    *matHeaderCellDef 
    [style.background-color]="color" 
    [style.color]="text_color"
    [style.font-weight]="text_weight"
    >
    {{ column | replaceText | titlecase }}
        
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element[column] }}
    </td>
    }
    <th mat-header-cell 
    *matHeaderCellDef 
    [style.background-color]="color"
    [style.color]="text_color"
    [style.font-weight]="text_weight"
    >
    {{ column | titlecase }}
    </th>
    @if(column == 'New Location'){
      <td mat-cell *matCellDef="let row ; let i = index;">
        <div *ngIf="row.readOnly;then content else other_content">here is ignored</div>
        <ng-template #content>{{ row[column] }}</ng-template>
        <ng-template #other_content>        
            <input type="text" 
              id="field_{{i}}" 
              formControlName="new_location" 
              (keyup)="new_location_check($event)"
            >
            <span id="error_{{i}}"></span>        
        </ng-template>
      </td>         
  }
  @if(table_title != 'smp_connectivity_table'){
    <td mat-cell *matCellDef="let row">
      <button
        mat-flat-button
        (click)="submitAction(row)"
      >
        Select
      </button>
    </td>
  }
    
    
      <td mat-cell *matCellDef="let row ; let i = index;">
        <div *ngIf="row.readOnly;then content else other_content">here is ignored</div>
        <ng-template #content></ng-template>
        <ng-template #other_content>   
          <span id = "error_span">*</span>     
            <mat-radio-group aria-label="Select an option" id="radio_{{i}}" required>
            <mat-radio-button value="yes" (change)="radioSelect($event,row,i)">Yes</mat-radio-button>
            <mat-radio-button value="no" (change)="radioSelect($event,row,i)">No</mat-radio-button>
            <mat-radio-button value="re_eval" (change)="radioSelect($event,row,i)" [style.display]="hide_radio_option ? 'none' : 'block'" >Re-eval</mat-radio-button>
            
          </mat-radio-group>       
        </ng-template>
      </td>
     
  
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" 
  [ngStyle]="row.excluded && {'display': 'none'}"></tr>
</table>